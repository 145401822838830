import Link from "next/link";

import { CustomDatoImage } from "components/CustomDatoImage/CustomDatoImage";
import { ResponsiveImage } from "services/datocms/generated";
import st from "./featured-article.module.scss";

const FeaturedArticle = ({
  heroVisual,
  slug,
  title,
}: {
  slug?: string;
  heroVisual?: ResponsiveImage | null;
  title: string;
}) => {
  return (
    <Link href={`/blog/${slug}`} className={st.link}>
      <div className={st.featuredArticleContainer}>
        {heroVisual && (
          <div className={st.coverImage}>
            <CustomDatoImage
              layout="fill"
              objectFit="cover"
              data={heroVisual}
              width={400}
            />
          </div>
        )}
        <div className={st.summaryContainer}>
          <div>
            <span>{title}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FeaturedArticle;
