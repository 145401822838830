/* eslint-disable @next/next/no-img-element */
import { Button } from "components/Button";
import WaterMakersDropdown from "components/MachineComparison/WaterMakersDropdown";
import { LocalizationContext } from "context/localization";
import { useTranslationContext } from "context/translation";
import Image from "next/image";
import Link from "next/link";
import { FC, useContext, useState } from "react";
import { StructuredText } from "react-datocms/structured-text";
import {
  AllProductsQuery,
  DatoProductForCategoryPageFragment,
} from "services/datocms/generated";
import { GetItemsByGtinQuery } from "services/graphql/generated";

const COLS = [0, 1, 2];

type ComparisonKeys =
  | "howItWorks"
  | "howToFizz"
  | "cylinderInsertion"
  | "bottleMaterial"
  | "dishwasherSafeBottles"
  | "compatibleBottles"
  | "dimensions";

type MachineComparisonProps = {
  products:
    | AllProductsQuery["allProducts"]
    | DatoProductForCategoryPageFragment[];
  pepdirectProducts?: NonNullable<GetItemsByGtinQuery["items"]>["items"];
};

export const MachineComparison: FC<MachineComparisonProps> = ({
  products,
  pepdirectProducts,
}) => {
  const machineTypes = ["art", "terra", "duo"];

  const machineBundles = products
    ?.filter((prod) => prod.slug && machineTypes.includes(prod.slug))
    .map((prod) => ({
      color: [
        ...(prod.color ? [{ hex: prod.color.hex }] : []),
        ...prod.colorVariants
          .map((variant) => variant?.color && { hex: variant.color.hex })
          .filter(
            (color): color is { hex: string } =>
              color !== null && color !== undefined,
          ),
      ],
      data: {
        howItWorks: prod.comparisonData?.howItWorks || "",
        howToFizz: prod.comparisonData?.howToFizz || "",
        cylinderInsertion: prod.comparisonData?.cylinderInsertion || "",
        bottleMaterial: prod.comparisonData?.bottleMaterial || "",
        dishwasherSafeBottles: prod.comparisonData?.dishwasherSafeBottles || "",
        compatibleBottles: prod.comparisonData?.compatibleBottles || "",
        dimensions: prod.comparisonData?.dimensions || "",
      },
      image: prod.plpThumbnail?.url ?? "",
      description: prod.shortDescription ?? "",
      slug: prod.slug ?? "",
      gtin: prod.gtin ?? "",
      categorySlug: prod.category?.slug ?? "",
    }));

  const [selectedMakers, setSelectedMakers] = useState(
    machineBundles?.slice(0, 3),
  );
  const { formatPriceByLocale } = useContext(LocalizationContext);

  const {
    howItWorksText = "How it works",
    howToFizzText = "How to Fizz",
    cylinderInsertionText = "",
    bottleMaterialText = "Bottle Material",
    dishwasherSafeBottlesText = "Dishwasher Safe",
    compatibleBottlesText = "Compatible Bottles",
    dimensionsText = "Dimensions",
    machineComparisonTitle = {
      value: "Machine Comparison",
    },
    shopNow = "Shop Now",
    startingAt = "Starting At",
    colors = "Colors",
    price = "Price",
  } = useTranslationContext();

  const makersData = {
    howItWorks: howItWorksText || "",
    howToFizz: howToFizzText || "",
    cylinderInsertion: cylinderInsertionText || "",
    bottleMaterial: bottleMaterialText || "",
    dishwasherSafeBottles: dishwasherSafeBottlesText || "",
    compatibleBottles: compatibleBottlesText || "",
    dimensions: dimensionsText || "",
  };

  return (
    <div className="container" id="porownaj-modele">
      <div className="text-center my-16">
        <StructuredText data={machineComparisonTitle?.value} />
      </div>
      <div className="pb-12">
        <div className="grid grid-cols-4 gap-4">
          <div />
          <div className="grid gap-2 grid-cols-2 lg:grid-cols-3 col-span-4 lg:col-span-3 p-3 lg:p-5">
            {selectedMakers.map((maker, index) => (
              <div
                key={index}
                className={`flex flex-col align-center items-center ${
                  index === selectedMakers.length - 1 && "hidden lg:flex"
                }`}
              >
                <Image
                  width={200}
                  height={200}
                  className="max-h-[200px] h-full w-auto"
                  src={maker.image}
                  alt=""
                />
                <div className="py-5 px-8 w-full">
                  <WaterMakersDropdown
                    makers={machineBundles}
                    selectedMaker={selectedMakers[index]}
                    onSelectMaker={(slctdMaker: any) => {
                      const newMakers = [...selectedMakers];
                      newMakers[index] = slctdMaker;
                      setSelectedMakers(newMakers);
                    }}
                  />
                </div>
                <p className="text-sm text-center font-semibold">
                  {maker.description}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:[&>*:nth-child(odd)]:bg-paleGray">
          <div className="grid grid-cols-4 gap-4">
            <div className="font-semibold p-3 lg:p-5 col-span-4 lg:col-span-1 text-center lg:text-start bg-paleGray lg:bg-transparent text-xs lg:text-base">
              {colors}
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 col-span-4 lg:col-span-3 p-3 lg:p-5">
              {COLS.map((index) => (
                <div
                  key={index}
                  className={`text-center text-sm ${
                    index === selectedMakers.length - 1 && "hidden lg:block"
                  }`}
                >
                  {selectedMakers[index]?.color?.map((variant, idx) => (
                    <span
                      key={idx}
                      className={`w-4 h-4 border-[1px] border-black border-f rounded-full inline-block m-1`}
                      style={{ backgroundColor: variant?.hex }}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
          {Object.entries(makersData).map(([key, value]) => {
            return (
              <div key={key} className="grid grid-cols-4 gap-4">
                <div className="font-semibold p-3 lg:p-5 col-span-4 lg:col-span-1 text-center lg:text-start bg-paleGray lg:bg-transparent text-xs lg:text-base">
                  {value}
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-3 col-span-4 lg:col-span-3 p-3 lg:p-5">
                  {COLS.map((index) => (
                    <div
                      key={index}
                      className={`text-center  text-xs lg:text-sm ${
                        index === selectedMakers.length - 1 && "hidden lg:block"
                      }`}
                    >
                      {selectedMakers[index]?.data?.[key as ComparisonKeys]}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
          <div className="grid grid-cols-4 gap-4">
            <div className="font-semibold p-3 lg:p-5 col-span-4 lg:col-span-1 text-center lg:text-start bg-paleGray lg:bg-transparent text-xs lg:text-base">
              {price}
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 col-span-4 lg:col-span-3 p-3 lg:p-5">
              {selectedMakers.map((maker, index) => (
                <div
                  key={index}
                  className={`text-center  text-xs lg:text-sm ${
                    index === selectedMakers.length - 1 && "hidden lg:block"
                  }`}
                >
                  {`${startingAt} ${formatPriceByLocale(
                    pepdirectProducts?.find((item) => item.gtin === maker.gtin)
                      ?.price || 0,
                  )}`}
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div />
            <div className="grid grid-cols-2 lg:grid-cols-3 col-span-4 lg:col-span-3 p-3 lg:p-5">
              {selectedMakers.map((maker, index) => (
                <div
                  key={index}
                  className={`text-center  text-xs lg:text-sm ${
                    index === selectedMakers.length - 1 && "hidden lg:block"
                  }`}
                >
                  <Link href={`/${maker.categorySlug}/${maker.slug}`}>
                    <Button>{shopNow}</Button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
