import cx from "classnames";
import { Button } from "components/Button";
import { CustomStructuredText } from "components/CustomStructuredText/CustomStructuredText";
import { FC } from "react";
import { SplitContentTextFragment } from "services/datocms/generated";

export const SplitContentText: FC<{
  content: SplitContentTextFragment;
}> = ({ content }) => {
  const { textContent, button, buttonBeforeText } = content;

  return (
    <div className="md:flex justify-center items-center">
      <div
        className={cx("flex flex-col", buttonBeforeText && "flex-col-reverse")}
      >
        {textContent && <CustomStructuredText content={textContent} />}
        {button && (
          <div
            className={cx(
              "flex my-5",
              button.horizontalPosition === "center" && "justify-center",
              button.horizontalPosition === "right" && "justify-end",
            )}
          >
            <Button>{button.buttonText}</Button>
          </div>
        )}
      </div>
    </div>
  );
};
