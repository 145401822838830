/* eslint-disable @next/next/no-img-element */
import AddToCartButton from "components/addToCartButton";
import { CustomDatoImage } from "components/CustomDatoImage/CustomDatoImage";
import { AppContext } from "context/app";
import { CartContext } from "context/cart";
import { LocalizationContext } from "context/localization";
import { getEventProduct } from "helpers/analyticsLogger";
import { getProductPrice, isProductOutOfStock } from "helpers/product";
import { getYotpoInstanceId } from "helpers/yotpo";
import { useCartAnalyticsEvents } from "hooks/analytics/useCartAnalyticsEvents";
import { useScreenSize } from "hooks/useScreenSize";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useContext, useLayoutEffect, useState } from "react";
import { DatoProductForCategoryPageFragment } from "services/datocms/generated";
import {
  BundleLineItem,
  CartFragment,
  DynamicBundleFragment,
  GetItemsByGtinQuery,
} from "services/graphql/generated";

type SingleProductProps = {
  datoProduct: DatoProductForCategoryPageFragment;
  pepdirectProduct?: NonNullable<GetItemsByGtinQuery["items"]>["items"][number];
  handleLogging: (item: string) => void;
  analytics: {
    itemCategory: string;
    itemListName: string;
  };
  loading?: boolean;
  categoryBgColor?: string;
  pepdirectBundle?: DynamicBundleFragment | null;
};

export const SingleProduct: FC<SingleProductProps> = ({
  datoProduct,
  pepdirectProduct,
  handleLogging,
  analytics,
  loading = false,
  categoryBgColor,
  pepdirectBundle,
}) => {
  const { tags, productImages, title } = datoProduct;

  const { formatPriceByLocale } = useContext(LocalizationContext);
  const {
    updateCartItem,
    cart,
    updateCartItemWithBundle,
    removeDynamicBundle,
  } = useContext(CartContext);
  const { addToCartEvent, removeFromCartEvent } = useCartAnalyticsEvents();
  const { tenant } = useContext(AppContext);
  const router = useRouter();
  const [updatingCart, setUpdatingCart] = useState(false);

  // We are using useLayoutEffect because we strictly need to make sure that the DOM element is attached so that Yotpo script can successfully attach and render the widget. With useEffect, there is no guarantee of having the element in the DOM even if we have the React state setup
  useLayoutEffect(() => {
    if (pepdirectProduct?.gtin) {
      (window as any)?.yotpoWidgetsContainer?.initWidgets();
    }
  }, [pepdirectProduct?.gtin]);

  let quantity = 0;
  cart?.lineItems.forEach((lineItem) => {
    if (
      lineItem.__typename === "CartItem" &&
      lineItem.item.id === pepdirectProduct?.id
    ) {
      quantity = lineItem.quantity;
    } else if (
      lineItem.__typename === "BundleLineItem" &&
      lineItem.bundleId === pepdirectBundle?.id
    ) {
      ++quantity;
    }
  });
  const currency = tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "";

  const handleAdjustItemCount = async (add: boolean = true) => {
    setUpdatingCart(true);
    const newQuantity = quantity + (add ? 1 : -1);

    const itemListName = `Collection - ${analytics.itemListName}`;

    const item = getEventProduct(pepdirectProduct, pepdirectBundle, {
      itemCategory: analytics.itemCategory,
      itemListName,
      currency,
      index: 0,
      quantity: 1,
    });

    const analyticsDetails = {
      currency,
      itemListName,
      items: [item],
    };

    let newCart: CartFragment | null = null;
    if (pepdirectBundle) {
      if (add) {
        newCart = await updateCartItemWithBundle({
          bundleTag: pepdirectBundle.tag,
          quantity: newQuantity,
          analyticsDetails,
        });
      } else {
        const lineItem = cart?.lineItems.find(
          (lineItem) =>
            lineItem.__typename === "BundleLineItem" &&
            lineItem.bundleId === pepdirectBundle.id,
        );
        if (lineItem) {
          await removeDynamicBundle(
            cart?.id,
            (lineItem as BundleLineItem).cartBundleReference,
          );
        }
      }
    } else {
      newCart = await updateCartItem({
        productId: pepdirectProduct?.id,
        quantity: newQuantity,
        subscriptionIntervalInDays: null,
        analyticsDetails: analyticsDetails,
      });
    }

    if (add) {
      await addToCartEvent(newCart, analyticsDetails);
    } else {
      await removeFromCartEvent(newCart, analyticsDetails);
    }
    setUpdatingCart(false);
  };

  const { isDesktop } = useScreenSize();
  const tagsToShow = isDesktop ? tags : tags.slice(0, 1);
  const price = formatPriceByLocale(
    getProductPrice(pepdirectProduct, pepdirectBundle) || 0,
  );
  const outOfStock =
    !loading && isProductOutOfStock(pepdirectProduct, pepdirectBundle);
  const productLink = `/${datoProduct.category?.slug}/${datoProduct.slug}`;

  return (
    <Link
      href={productLink}
      onClick={(event) => {
        event.preventDefault();
        handleLogging(pepdirectProduct?.id);
        router.push(productLink);
      }}
    >
      <div
        className={`flex flex-col gap-3 justify-between items-center p-5 h-full ${
          categoryBgColor?.length ? `bg-[${categoryBgColor}]` : ""
        }`}
      >
        {tagsToShow?.length > 0 && (
          <div className="overflow-hidden w-full">
            <div className="flex flex-wrap gap-1 justify-center">
              {tagsToShow.map((tag) => (
                <div
                  key={tag.id}
                  className="text-xs p py-1 px-4 rounded-full text-nowrap whitespace-nowrap"
                  style={{
                    backgroundColor: tag.backgroundColor?.hex,
                    color: tag.textColor?.hex,
                  }}
                >
                  {tag.tag}
                </div>
              ))}
            </div>
          </div>
        )}
        {productImages?.[0].responsiveImage && (
          <CustomDatoImage
            data={productImages?.[0].responsiveImage}
            width={300}
          />
        )}
        <p className="text-primary text-sm lg:text-base font-medium text-center">
          {title}
        </p>
        {pepdirectProduct?.gtin && (
          <div
            className="yotpo-widget-instance"
            data-yotpo-instance-id={getYotpoInstanceId("stars")}
            data-yotpo-product-id={pepdirectProduct?.gtin}
            data-yotpo-section-id="collection"
          />
        )}
        {loading ? (
          <div className="rounded-md w-[120px] h-[16px] bg-gray-200 animate-pulse-opacity" />
        ) : (
          <p className="text-xs text-primary font-bold lg:text-[22px]">
            {price}
          </p>
        )}

        <AddToCartButton
          updatingCart={updatingCart}
          quantity={quantity}
          outOfStock={outOfStock}
          handleAdjustItemCount={handleAdjustItemCount}
          loading={loading}
        />
      </div>
    </Link>
  );
};
