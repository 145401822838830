import { cookies, decovoShopAppId, endpoints, isProductionReady } from "config";
import { CartFragment } from "services/graphql/generated";
import Cookies from "universal-cookie";
import URI from "urijs";

const appId = decovoShopAppId;
const cookie = new Cookies();
const cookieOptions = {
  path: "/",
  secure: isProductionReady,
  domain: URI(endpoints?.web || "").domain(),
  maxAge: 365 * 24 * 60 * 60,
};

export const getCartIdFromCookie = () => cookie.get(cookies.cartCookieName);

export const saveCartCookie = (id: string) => {
  // Attempt to remove the cookie set w/o a proper domain first,
  // otherwise the set will fail.
  cookie.remove(cookies.cartCookieName);
  cookie.set(cookies.cartCookieName, id, cookieOptions);
};

export const deleteCartCookie = () => {
  cookie.remove(cookies.cartCookieName, cookieOptions);
  // Attempt to remove cookies that may have been set on the wrong domain
  cookie.remove(cookies.cartCookieName);
};

export const getCheckoutUrl = (): string =>
  `${endpoints.checkout}/v3/${getCartIdFromCookie()}/${appId}`;

// returns true if at least 1 item has a subscription
// no subscriptions for BundleLineItem only need to check CartItem
export const cartHasSubscriptionItem = (cart: CartFragment): boolean => {
  return cart?.lineItems?.some(
    (lineItem) =>
      lineItem.__typename === "CartItem" && lineItem.subscriptionIntervalInDays,
  );
};

export const getErrors = (cart: CartFragment | null): string[] => {
  const errors: string[] = [];
  cart?.lineItems.forEach((lineItem) => {
    if (lineItem?.__typename === "CartItem") {
      const {
        quantity,
        item: { inventoryQty, isOutOfStock },
      } = lineItem;

      if (isOutOfStock || !inventoryQty) {
        errors.push("noStock");
      }
      if (inventoryQty && inventoryQty < quantity) {
        errors.push("lowStock");
      }
    }
  });
  return errors;
};
