import getConfig from "next/config";

const { publicRuntimeConfig = {} } = getConfig();

export const environment = publicRuntimeConfig.environment as string;
export const sentryEnvironment =
  publicRuntimeConfig.sentryEnvironment as string;
export const isProductionReady = ["staging", "production", "uat"].includes(
  environment,
);
export const isLocalEnv = !isProductionReady && !process.env.CI;

// TODO: Change to handle multiple appId's
export const decovoShopAppId = "e0225c6f-2285-493c-8205-8f42a92b8655";
export const sentryDSN = publicRuntimeConfig.sentryDSN as string;

export const imgWidths = [345, 600, 690];

export const shipping = {
  us: {
    free: 0,
    below: "0",
    threshold: 1000000000000000000000000,
  },
};

export const cart = {
  discounts: {
    hideDiscountsMaxThreshold: 2,
    showSavingsMaxThreshold: 10,
  },
  maxCartQty: 15,
};

export const cookies = {
  localStorageUserKey: "caps_user",
  cartCookieName: "decovo-shoppingcart",
  secureCookie: isProductionReady,
  zipCode: "user-zip-code",
};

export const endpoints = {
  cdn: publicRuntimeConfig.cdn as string,
  productsImgCdn: publicRuntimeConfig.productsImgCdn as string,
  web: publicRuntimeConfig.web as string,
  api: publicRuntimeConfig.api as string,
  cart: publicRuntimeConfig.cart as string,
  checkout: publicRuntimeConfig.checkout as string,
  portalService: publicRuntimeConfig.portalService as string,
  portalFrontend: publicRuntimeConfig.portalFrontend as string,
  corporateFrontend: publicRuntimeConfig.corporateFrontend as string,
  internalApi: publicRuntimeConfig.internalApi as string,
  datoCmsApiToken: publicRuntimeConfig.datoCmsApiToken as string,
  datoCmsEndpoint: publicRuntimeConfig.datoCmsEndpoint as string,
  yotpo: publicRuntimeConfig.yotpo as string,
};

export const headerPromotion = {
  shouldShow: false,
  isAlwaysVisible: false,
  promotionText: "Free Shipping Over $20",
};

export const links = {
  support: "support@sodastream.com",
};

export const ITEM_BRAND = "SodaStream";

type DynamicYieldPageContext =
  | "HOMEPAGE"
  | "CATEGORY"
  | "PRODUCT"
  | "CART"
  | "POST"
  | "OTHER";

interface DYRecommendationContext {
  type: DynamicYieldPageContext;
  data?: string[];
}

declare global {
  interface Window {
    DY: {
      recommendationContext?: DYRecommendationContext;
    };
  }
}

export function setDYContext(type: DynamicYieldPageContext, data?: string[]) {
  window.DY = window.DY || {};
  window.DY.recommendationContext = { type: type, data: data };
}
