import { AppContext } from "context/app";
import { CartContext } from "context/cart";
import { useAppProvider } from "context/hooks/useAppProvider";
import { useCartProvider } from "context/hooks/useCartProvider";
import { useLocalizationProvider } from "context/hooks/useLocalizationProvider";
import { useUserProvider } from "context/hooks/useUserProvider";
import { LocalizationContext } from "context/localization";
import { UserContext } from "context/user";
import getConfig from "next/config";
import Router from "next/router";
import NProgress from "nprogress";
import { useEffect } from "react";

import "../styles/index.scss";
import "./styles.css";

import "react-datepicker/dist/react-datepicker.css";

import App, {
  AppInitialProps,
  AppProps,
  AppContext as NextAppContext,
} from "next/app";

/* Importing and customizing nprogress */
import { META_DEFAULT_OG_IMAGE, PRIMARY_COLOR } from "constants/meta";
import Head from "next/head";
import "styles/nprogress.scss";

/* Customizing react slick */
import "styles/slick.scss";

import { ApolloProvider } from "@apollo/client";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import MobileHeader from "components/MobileHeader/MobileHeader";
import { Popup } from "components/Popup/Popup";
import { AnalyticsSessionParamsContext } from "context/analyticsSessionParams";
import { useAnalyticsSessionParamsProvider } from "context/hooks/useAnalyticsSessionParamsProvider";
import { useLogProvider } from "context/hooks/useLogProvider";
import { useTranslationProvider } from "context/hooks/useTranslationProvider";
import { LogContext } from "context/log";
import { TranslationContext } from "context/translation";

import { getStartupData } from "helpers/cache";
import {
  AllPopupsQuery,
  DesktopHeaderQuery,
  FooterQuery,
  MobileHeaderQuery,
  TranslationQuery,
} from "services/datocms/generated";
import client from "services/graphql/client";
import { TenantQuery } from "services/graphql/generated";
import "styles/stylingOverrides.scss";

// Init progress bar
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

interface SodastreamInitialProps extends AppInitialProps {
  footerData: FooterQuery["footer"];
  headerData: DesktopHeaderQuery["desktopHeader"];
  locale: string;
  mobileHeader: MobileHeaderQuery["mobileHeader"];
  popups: AllPopupsQuery["allPopups"];
  tenant: TenantQuery;
  translation: TranslationQuery["translation"];
}

interface SodastreamAppProps extends AppProps {
  footerData: FooterQuery["footer"];
  headerData: DesktopHeaderQuery["desktopHeader"];
  locale: string;
  mobileHeader: MobileHeaderQuery["mobileHeader"];
  popups: AllPopupsQuery["allPopups"];
  tenant: TenantQuery;
  translation: TranslationQuery["translation"];
}

Sodastream.getInitialProps = async (
  appContext: NextAppContext,
): Promise<SodastreamInitialProps> => {
  const appProps = App.getInitialProps(appContext);
  const startupData = await getStartupData(appContext);
  return {
    pageProps: (await appProps).pageProps,
    footerData: startupData.footerData,
    headerData: startupData.headerData,
    locale: startupData.locale,
    mobileHeader: startupData.mobileHeader,
    popups: startupData.popups,
    tenant: startupData.tenant,
    translation: startupData.translation,
  };
};

// Revisit this later if layout needs to change https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export default function Sodastream({
  Component,
  footerData,
  headerData,
  locale,
  mobileHeader,
  pageProps,
  popups,
  tenant,
  translation,
}: SodastreamAppProps): JSX.Element {
  const gtmId = tenant?.tenant?.analyticsConfig?.gtm?.containerId;

  useEffect(() => {
    // exposes pod hostname to browser window
    if (window) {
      const { publicRuntimeConfig } = getConfig();
      window._pepdirect = {
        ...window._pepdirect,
        hostname: publicRuntimeConfig.hostname,
        commit: process.env.NEXT_PUBLIC_GIT_SHA || "",
      };
    }
  }, []);

  const defaultCurrencyCode =
    tenant.tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "USD";

  const { appContextValue } = useAppProvider(tenant.tenant);
  const { userContextValue } = useUserProvider(client);
  const { cartContextValue } = useCartProvider(
    client,
    userContextValue.currentUserId,
  );
  const { useLogPageView } = useLogProvider(
    userContextValue.currentUserId,
    gtmId,
    locale,
    defaultCurrencyCode,
  );
  const { analyticsParamsValue } = useAnalyticsSessionParamsProvider();
  const { localizationContextValue } =
    useLocalizationProvider(defaultCurrencyCode);
  const translationData = useTranslationProvider(translation ?? {});

  return (
    <>
      <Head>
        {/* Meta tags not intended to change */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content={PRIMARY_COLOR} />
        <meta name="og:price:currency" content={defaultCurrencyCode} />
        {/* Meta tags */}
        <meta name="og:image" content={META_DEFAULT_OG_IMAGE} key="og:image" />
        <meta name="og:type" content="website" key="og:type" />
      </Head>
      <ApolloProvider client={client}>
        <AppContext.Provider value={appContextValue}>
          <LocalizationContext.Provider value={localizationContextValue}>
            <TranslationContext.Provider value={translationData}>
              <UserContext.Provider value={userContextValue}>
                <CartContext.Provider value={cartContextValue}>
                  <LogContext.Provider value={{ useLogPageView }}>
                    <AnalyticsSessionParamsContext.Provider
                      value={analyticsParamsValue}
                    >
                      <main
                        className={"app"}
                        data-commit={process.env.NEXT_PUBLIC_GIT_SHA || ""}
                      >
                        <Header headerData={headerData} />
                        <MobileHeader headerData={mobileHeader} />
                        <Component {...pageProps} />
                        <Footer footerData={footerData} />
                        {popups.map((popup, index) => (
                          <Popup key={index} popup={popup} />
                        ))}
                      </main>
                    </AnalyticsSessionParamsContext.Provider>
                  </LogContext.Provider>
                </CartContext.Provider>
              </UserContext.Provider>
            </TranslationContext.Provider>
          </LocalizationContext.Provider>
        </AppContext.Provider>
      </ApolloProvider>
    </>
  );
}
