import cx from "classnames";
import { Button } from "components/Button";
import { AppContext } from "context/app";
import { useTranslationContext } from "context/translation";
import { useFlavorsAndBottlesAnalytics } from "hooks/analytics/useFlavorsAndBottlesAnalytics";
import NextImage from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, useContext, useMemo, useState } from "react";
import {
  DatoProductForCategoryPageFragment,
  ProductCategoryFragment,
} from "services/datocms/generated";
import { GetItemsByGtinQuery } from "services/graphql/generated";
import st from "./BottleListing.module.scss";
import { CategoryHero } from "./CategoryHero";
import { PromotionalCard } from "./PromotionalCard";
import { SingleProduct } from "./SingleProduct";

interface BottleListingProps {
  category: ProductCategoryFragment;
  products: DatoProductForCategoryPageFragment[];
  pepdirectProducts?: NonNullable<GetItemsByGtinQuery["items"]>["items"];
  loading?: boolean;
}

export const BottleListing: FC<BottleListingProps> = ({
  products,
  category,
  pepdirectProducts,
  loading = false,
}) => {
  const {
    compatibleMachinesFilterContainerHeading,
    compatibleMachinesFilterEmptyFilterText,
    compatibleMachinesFilterMainHeading,
    compatibleMachinesFilterFilledFilterText,
  } = useTranslationContext();
  const router = useRouter();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const {
    analyticsCategoryName,
    analyticsName,
    description,
    heroImage,
    heroImageMobile,
    heroText,
    promotionalCard,
    plpItemBackgroundColor,
  } = category || {};

  const { id: selectedCompatibleMachine } = router.query;

  const { tenant } = useContext(AppContext);
  const currency = tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "";

  const { handleLogging } = useFlavorsAndBottlesAnalytics(
    currency,
    analyticsName,
    analyticsCategoryName,
    products,
    pepdirectProducts,
  );

  const availableCompatibleMachines = products
    ?.map((product) => product.compatibleMachines)
    ?.flat()
    .sort((a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()))
    .reduce<DatoProductForCategoryPageFragment[]>(
      (prev, curr) =>
        prev.length === 0
          ? [curr]
          : prev?.[prev.length - 1]?.id !== curr.id
            ? ([...prev, curr] as any)
            : prev,
      [],
    );

  const productsFilteredWithCompatibleMachines =
    category?.showCompatibleMachinesFilter && selectedCompatibleMachine
      ? products?.filter(
          (product) =>
            product.compatibleMachines?.some(
              (machine) => machine.slug === selectedCompatibleMachine,
            ),
        )
      : products;

  const selectedCompatibleMachineTitle = useMemo(
    () =>
      availableCompatibleMachines?.find(
        (machine) => machine.slug === selectedCompatibleMachine,
      )?.title,
    [availableCompatibleMachines, selectedCompatibleMachine],
  );

  return (
    <>
      <CategoryHero
        heroImage={heroImage?.responsiveImage}
        heroImageMobile={heroImageMobile?.responsiveImage}
        heroText={heroText}
        description={description}
      />
      <div className="container p-6 flex flex-col gap-10">
        {category?.showCompatibleMachinesFilter ? (
          <div>
            <p className={st.filterHeader}>
              {compatibleMachinesFilterMainHeading}
            </p>
            <div
              onClick={() => setIsFilterOpen((prev) => !prev)}
              className={st.filterButtonContainer}
            >
              <span className={st.filterButtonText}>
                {selectedCompatibleMachine
                  ? `${compatibleMachinesFilterFilledFilterText}: ${selectedCompatibleMachineTitle}`
                  : compatibleMachinesFilterEmptyFilterText}
              </span>
              <svg
                className={st.filtereChevronSvg}
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Collection-pages-+-bottles-compatibility"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Bottles-collection_7_filters_ON-hove"
                    transform="translate(-379.000000, -573.000000)"
                    stroke="#000000"
                    strokeWidth="1.2"
                  >
                    <g id="Group" transform="translate(131.000000, 561.000000)">
                      <g
                        id="drop-down-arrow-black"
                        transform="translate(248.000000, 12.000000)"
                      >
                        <line
                          x1="0.789473684"
                          y1="1.05412874"
                          x2="7.62738189"
                          y2="8.33333333"
                          id="line"
                        ></line>{" "}
                        <line
                          x1="7.10526316"
                          y1="0.833333333"
                          x2="13.9431714"
                          y2="8.11253793"
                          id="line-copy"
                          transform="translate(10.524217, 4.472936) scale(-1, 1) translate(-10.524217, -4.472936) "
                        ></line>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            {isFilterOpen ? (
              <div className={st.filterItemsContainer}>
                <p className={st.filterItemsContainerHeading}>
                  {compatibleMachinesFilterContainerHeading}
                  <Button
                    onClick={() => {
                      setIsFilterOpen(false);
                      router.push(`/${category?.slug}`, undefined, {
                        shallow: false,
                        scroll: true,
                      });
                    }}
                    className={st.filterItemsContainerCloseButton}
                  >
                    <svg
                      width="12px"
                      height="12px"
                      viewBox="0 0 12 12"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Collection-pages-+-bottles-compatibility"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Bottles-collection_7_filters_ON-hove"
                          transform="translate(-1161.000000, -635.000000)"
                          fill="#BCBCBC"
                          fillRule="nonzero"
                        >
                          <g
                            id="Select-machine-drop-down"
                            transform="translate(-8.000000, 600.000000)"
                          >
                            <g
                              id="remove"
                              transform="translate(1169.000000, 35.000000)"
                            >
                              <path d="M7.69705624,6 L11.6485282,9.95147184 C12.1171573,10.420101 12.1171573,11.179899 11.6485282,11.6485282 C11.179899,12.1171573 10.420101,12.1171573 9.95147184,11.6485282 L6,7.69705624 L2.04852816,11.6485282 C1.57989896,12.1171573 0.82010104,12.1171573 0.35147184,11.6485282 C-0.11715728,11.179899 -0.11715728,10.420101 0.35147184,9.95147184 L4.30294376,6 L0.35147184,2.04852816 C-0.11715728,1.57989896 -0.11715728,0.82010104 0.35147184,0.35147184 C0.82010104,-0.11715728 1.57989896,-0.11715728 2.04852816,0.35147184 L6,4.30294376 L9.95147184,0.35147184 C10.420101,-0.11715728 11.179899,-0.11715728 11.6485282,0.35147184 C12.1171573,0.82010104 12.1171573,1.57989896 11.6485282,2.04852816 L7.69705624,6 L7.69705624,6 Z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </Button>
                </p>
                <div className={st.filterItemsContainerScrollContainer}>
                  {availableCompatibleMachines?.map((machine) => (
                    <Link
                      shallow
                      scroll={false}
                      key={machine.id}
                      href={`/${category?.slug}/${machine.slug}`}
                    >
                      <div
                        key={machine.id}
                        className={cx(
                          st.machineContainer,
                          selectedCompatibleMachine === machine.slug
                            ? st.selectedCompatibleMachineContainer
                            : "",
                        )}
                      >
                        <NextImage
                          src={machine.plpThumbnail?.url || ""}
                          width={150}
                          height={200}
                          className={st.machineImg}
                          alt={machine.title || ""}
                        />
                        <p className={st.machineTitle}>{machine.title}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
          {productsFilteredWithCompatibleMachines.map((product, index) => (
            <Fragment key={index}>
              {promotionalCard && promotionalCard.cardPosition === index && (
                <PromotionalCard cardData={promotionalCard} />
              )}
              <SingleProduct
                datoProduct={product}
                pepdirectProduct={pepdirectProducts?.find(
                  (item) => item.gtin === product?.gtin,
                )}
                handleLogging={handleLogging}
                analytics={{
                  itemCategory: category?.analyticsCategoryName ?? "",
                  itemListName: category?.analyticsName ?? "",
                }}
                loading={loading}
                categoryBgColor={plpItemBackgroundColor?.hex ?? ""}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
