var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"de45983-sodastream-storefront-pl"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import {
  environment,
  isProductionReady,
  sentryDSN,
  sentryEnvironment,
} from "config";

const releaseId = process.env.SENTRY_RELEASE;

Sentry.init({
  debug: false,
  enabled: isProductionReady,
  dsn: sentryDSN || "",
  environment: sentryEnvironment || environment,
  release: releaseId,
  allowUrls: [
    /https?:\/\/assets\.?production\.decovostatic\.com/,
    /https?:\/\/assets\.?staging\.decovostatic\.com/,
    /https?:\/\/assets\.?uat\.decovostatic\.com/,
  ],
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeSend(event, hint) {
    try {
      const frames = event?.exception?.values?.[0]?.stacktrace?.frames || [];

      if (frames?.length) {
        const excludedFilenames = [`<anonymous>`, `/gtm.js`];
        const excludedFilenameAtIndex = (i: number) =>
          excludedFilenames.includes(frames?.[i]?.filename || "");

        if (excludedFilenameAtIndex(0)) return null;

        // catches excludedFilenames from injected anonymous
        // gtm script matching this common sentry error pattern
        if (frames.length === 2 && excludedFilenameAtIndex(1)) return null;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return event;
  },
});

Sentry.configureScope((scope) => scope.setLevel("error"));
