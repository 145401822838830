import cx from "classnames";
import { colors } from "styles/variables";
import st from "./Spinner.module.scss";

interface SpinnerProps {
  color?: string;
  dotSize?: string; // for height and width css values
  className?: string;
}

export const Spinner = ({
  color,
  dotSize,
  className,
}: SpinnerProps): JSX.Element => {
  const dotStyle = {
    width: dotSize || "10px",
    height: dotSize || "10px",
    backgroundColor: color ?? colors.primaryDeepBlue,
  };

  return (
    <div
      data-testid="spinner"
      className={cx(st.spinnerContainer, className || "")}
    >
      <div className={st.spinner}>
        <div className={st.dot} style={dotStyle} />
        <div className={st.dot} style={dotStyle} />
        <div className={st.dot} style={dotStyle} />
      </div>
    </div>
  );
};
