import { Button } from "components/Button";
import { LocalizationContext } from "context/localization";
import { useTranslationContext } from "context/translation";
import { getYotpoInstanceId } from "helpers/yotpo";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useCallback, useContext, useLayoutEffect, useMemo } from "react";
import { Image } from "react-datocms/image";
import {
  DatoProductForCategoryPageFragment,
  ProductCategoryFragment,
} from "services/datocms/generated";
import { GetItemsByGtinQuery } from "services/graphql/generated";
import { isWhite } from "utils/isWhite";
import st from "./machine-product.module.scss";

interface MachineProductProps {
  datoProduct: DatoProductForCategoryPageFragment;
  pepdirectProduct?: NonNullable<GetItemsByGtinQuery["items"]>["items"][number];
  handleLogging: (itemId: string) => void;
  productIdsSlugs: { id: string; slug: string }[];
  loading?: boolean;
  buttonStyle: NonNullable<ProductCategoryFragment>["itemButton"];
}

export const MachineProduct: FC<MachineProductProps> = ({
  datoProduct,
  handleLogging,
  pepdirectProduct,
  productIdsSlugs,
  loading = false,
  buttonStyle,
}) => {
  const { formatPriceByLocale } = useContext(LocalizationContext);
  // We are using useLayoutEffect because we strictly need to make sure that the DOM element is attached so that Yotpo script can successfully attach and render the widget. With useEffect, there is no guarantee of having the element in the DOM even if we have the React state setup
  useLayoutEffect(() => {
    if (pepdirectProduct?.gtin) {
      (window as any)?.yotpoWidgetsContainer?.initWidgets();
    }
  }, [pepdirectProduct?.gtin]);

  const colorOptions = useMemo(() => {
    const options = [
      {
        color: datoProduct?.color?.hex || "",

        slug: datoProduct?.slug || "",
        analyticsColor: datoProduct.analyticsColorName || "",
        id:
          productIdsSlugs.find((id) => id.slug === datoProduct?.slug)?.id || "",
      },
    ];

    datoProduct?.colorVariants.forEach((variant) => {
      variant?.color?.hex &&
        options.push({
          color: variant.color.hex,
          slug: variant?.slug || "",
          analyticsColor: variant.analyticsColorName || "",
          id: productIdsSlugs.find((id) => id.slug === variant?.slug)?.id || "",
        });
    });

    return options;
  }, [datoProduct, productIdsSlugs]);

  const { compareTo, startingAt } = useTranslationContext();

  const router = useRouter();

  const price = formatPriceByLocale(pepdirectProduct?.price || 0);

  const categorySlug = datoProduct.category?.slug;
  const linkSlug = colorOptions ? colorOptions?.[0]?.slug : "";
  const linkId = colorOptions ? colorOptions?.[0]?.id : "";
  const productLink = `${datoProduct.category?.slug}/${linkSlug}`;

  const linkOnClickHandler = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault();
      handleLogging(linkId);
      router.push(productLink);
    },
    [linkId, productLink, handleLogging, router],
  );

  return (
    <div key={datoProduct?.gtin} className={st.item}>
      <div className={st.itemImageContainer}>
        <Link
          href={`/${categorySlug}/${linkSlug}`}
          onClick={linkOnClickHandler}
        >
          {datoProduct.plpThumbnail?.responsiveImage && (
            <Image
              className={st.itemImage}
              data={datoProduct.plpThumbnail?.responsiveImage}
            />
          )}
        </Link>
      </div>
      <div className={st.infoContainer}>
        <Link
          href={`/${categorySlug}/${linkSlug}`}
          onClick={linkOnClickHandler}
        >
          <div className={st.infoDivider}>
            <span className={st.itemTitle}>
              {datoProduct?.groupTitle ||
                datoProduct?.title?.split(" ")?.[0] ||
                ""}
            </span>
            {pepdirectProduct?.gtin && (
              <div
                className="yotpo-widget-instance"
                data-yotpo-instance-id={getYotpoInstanceId("stars")}
                data-yotpo-product-id={pepdirectProduct?.gtin}
                data-yotpo-section-id="collection"
              />
            )}
            <span className={st.itemDescription}>
              {datoProduct?.shortDescription}
            </span>
          </div>
        </Link>
        <div className={st.infoDivider}>
          <div className={st.colorOptionsContainer}>
            {colorOptions?.map((option, index) => {
              const optionLink = `/${datoProduct.category?.slug}/${option.slug}`;
              return (
                <Link
                  className={st.colorLink}
                  href={optionLink}
                  key={index}
                  bi-type="Sparkling Water Makers plp - Color selection"
                  bi-id={option.analyticsColor}
                  onClick={(event) => {
                    event.preventDefault();
                    handleLogging(option.id);
                    router.push(optionLink);
                  }}
                >
                  {index === 0 && (
                    <span
                      className={st.selectedColor}
                      style={{
                        borderColor: isWhite(option.color)
                          ? "#8B9195"
                          : option.color,
                      }}
                    />
                  )}
                  <div
                    className={st.colorOption}
                    style={{
                      backgroundColor: option.color,
                      border: isWhite(option.color)
                        ? "0.65px solid #8B9195"
                        : "none",
                      width: index === 0 ? "20px" : "25px",
                      height: index === 0 ? "20px" : "25px",
                    }}
                  />
                </Link>
              );
            })}
          </div>
          <Link
            className={st.compareTableLink}
            href={"#porownaj-modele"}
            bi-type="Sparkling Water Makers plp - Compare table"
            bi-id="Compare table"
          >
            {compareTo}
          </Link>
          <Button
            onClick={() => {
              handleLogging(datoProduct.id);
              router.push(
                `/${datoProduct.category?.slug}/${datoProduct?.slug}`,
              );
            }}
            style={{
              backgroundColor: buttonStyle?.backgroundColor?.hex,
              color: buttonStyle?.color?.hex,
            }}
            className={st.viewOffersButton}
            bi-type="Sparkling Water Makers plp - Go to pdp"
            bi-id={pepdirectProduct?.title}
          >
            {loading ? "..." : `${startingAt} ${price}`}
          </Button>
        </div>
      </div>
    </div>
  );
};
