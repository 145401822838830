import { FC, ReactNode, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "components/spinner/Spinner";
import st from "./Button.module.scss";

const spinnerSize: Record<NonNullable<ButtonProps["size"]>, string> = {
  large: "10px",
  medium: "8px",
  small: "6px",
};

const TIME_BEFORE_SPINNER = 100;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: "filled" | "outlined" | "unstyled";
  size?: "large" | "medium" | "small";
  fullWidth?: boolean;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  variant = "filled",
  size = "large",
  fullWidth,
  disabled,
  loading,
  ...restProps
}) => {
  const [isLoadingDisplayed, setIsLoadingDisplayed] = useState(!!loading);

  useEffect(() => {
    let timeout: number;
    if (!loading && isLoadingDisplayed) {
      setIsLoadingDisplayed(false);
    } else if (loading && !isLoadingDisplayed) {
      timeout = window.setTimeout(() => {
        if (loading && !isLoadingDisplayed) setIsLoadingDisplayed(true);
      }, TIME_BEFORE_SPINNER);
      return () => clearTimeout(timeout);
    }
  }, [isLoadingDisplayed, loading]);

  return (
    <button
      disabled={disabled || loading}
      className={twMerge(
        ...(variant === "filled" || variant === "outlined"
          ? [
              "inline-flex  hover:opacity-85 h-12 rounded-full font-semibold justify-center items-center flex-nowrap w-fit leading-[21px] hover:bg-[#112340]",
              variant === "filled" && "bg-primary text-white",
              variant === "outlined" &&
                "bg-white text-primary border border-primary hover:text-white hover:bg-primary",
              (disabled || loading) && "!bg-[#ccc] border-none text-white",
              size === "large" && "text-base px-8",
              size === "medium" && "py-4",
              size === "small" && "px-4 h-[30px] text-[13px]",
            ]
          : []),
        fullWidth && "!w-full",
        className,
      )}
      {...restProps}
    >
      {loading ? (
        <div className={st.loadingWrapper}>
          <div className={st.loading}>
            <Spinner
              color={variant === "unstyled" ? "#ccc" : "white"}
              dotSize={spinnerSize[size]}
            />
          </div>
          {/* children are in the loading wrapper to maintain the height/width of the button when showing the spinner */}
          <div className={st.hiddenChildrenWrapper}>{children}</div>
        </div>
      ) : (
        // If someone is using google translate, then they may get the follwing Runtime Error when `loading` changes from false to true -
        // "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
        // See https://github.com/facebook/react/issues/11538#issuecomment-390386520
        // Wrapping the children in a `span` as a workaround to prevent that error.
        <span className={st.childrenWrapper}>{children}</span>
      )}
    </button>
  );
};
